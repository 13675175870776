export const FaqMock =  [
  {
    id: 1,
    show: false,
    question: {
      kk: '«Smart Contract» сервисі. Бұл не?',
      ru: 'Сервис «Smart Contract». Что это?',
      en: '«Smart Contract» service. What is it?'
    },
    answer: {
      kk: 'ҚР ҰКО ЭЦҚ арқылы осындай шарттарға қол қоя отырып, шарттарды электрондық түрде жасауға, сақтауға бағытталған бизнес-қағидаларды басқарудың ақпараттық жүйесі. Шарттың типіі мен түріне байланысты келісімшарттың белгілі бір талаптары автоматты түрде орындалады (немесе қамтамасыз етіледі). «Smart Contract» сервисі sc.egov.kz сайтта және «eGov mobile» мобильді қосымшасында ұсынылған.',
      ru: 'Информационная система управления бизнес-правилами, направленная на заключение, хранение договоров в электронном виде с подписанием таких договоров посредством ЭЦП НУЦ РК. В зависимости от типа и вида договора определённые условия контракта исполняются (или обеспечиваются) автоматически. Сервис «Smart contract» представлен на сайте sc.egov.kz и мобильном приложении «eGov mobile».',
      en: 'An information system for managing business rules aimed at concluding and storing contracts in electronic form with the signature of such contracts using the DS of the NCA RK. Depending on the type and nature of the contract, certain terms of the contract are automatically executed (or ensured). The «Smart contract» service is available on the sc.egov.kz website and the «eGov mobile» application.'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Бұл қалай жұмыс істейді?',
      ru: 'Как это работает?',
      en: 'How does this work?'
    },
    answer: {
      kk: `<ol>
        <li>Авторландыру <br/>
          sc.egov.kz сайттында «eGov арқылы кіру» батырмасын басыңыз. Электрондық үкімет порталындағы өз есептік жазбаңыздың көмегімен ЭЦҚ арқылы авторизациясыдан өтіңіздер. Немесе «eGov mobile» мобильді қосымшасында ЭЦҚ арқылы авторизациялаңыз және «Менің шарттарым» сервисін таңдаңыз.
          <br/>Қызмет 3 бөлімнен тұрады:
          <ul>
            <li>* Менің шарттарым – пайдаланушы жасаған шарттардың тізімі</li>
            <li>* Бірлескен шарттар – пайдаланушы екінші тарап ретінде әрекет ететін шарттардың тізімі.</li>
            <li>* Пайдаланушының дербес деректерін пайдалану бойынша келісім беруге жіберілген және алынған сұрауларды көруге болатын дербес деректерді өңдеу. Мұнда сұрау салу мәртебесі көрсетіледі, келісім мәтінімен танысуға болады.</li>
          </ul>
          <br/>
        </li>
        <li>Келісім сұрау<br/>
          Таңдалған шартты жасамас бұрын екінші тараптың дербес деректерді жинауға және өңдеуге келісімін алу қажет.
          <br/>Ол былай жасалады. «Келісімшарт жасау» батырмасын басыңыз, істі таңдаңыз, «Келісімді сұрау» батырмасын басыңыз, екінші Тараптың ЖСН-ін көрсетіңіз, қажет болған жағдайда түсініктеме бере аласыз. «Жіберу» батырмасын басыңыз.
          <br/>Екінші тарапқа келісім беру қажеттілігі туралы хабарлама келеді.
          <br/><br/>
        </li>
        <li>Деректерді толтыру.<br/>
        Келісім берілгеннен кейін келісімшарт құруға және барлық қажетті өрістерді толтыруға көшуге болады. Ол үшін таңдалған Шарттың атауының оң жағындағы көрсеткіні басу керек.
        <br/>Деректерді толтырғаннан кейін келісімшартты «Жасау» батырмасын басыңыз.
        <br/><br/>
        </li>
        <li>Қол қою.<br/>
        Толтырылғаннан кейін Шартқа «Жоба» мәртебесі беріледі, Сіз енгізілген деректерді тексере аласыз, содан кейін Шартқа қол қоя аласыз.
        <br/>Осыдан кейін екінші тарап «Бірлескен шарттар» бөлімінде Шартты көре алады, талаптармен танысып, өз тарапынан Шартқа қол қояды.
        </li>
        </ol>`,
      ru: `
      <ol>
        <li>Авторизация<br/>
          На сайте sc.egov.kz нажмите на кнопку "Войти с помощью eGov". Авторизуйтесь при помощи своей ученой записи на Портале электронного правительства посредством ЭЦП. Либо авторизуйтесь посредством ЭЦП в мобильном приложении eGov mobile и выберите сервис «Мои договора».
          <br/>Сервис состоит из 3 разделов:
          <ul>
            <li>* Мои договора – список договоров, созданных пользователем</li>
            <li>* Договоры с моим участием – список договоров, в которых пользователь выступает в качестве второй стороны.</li>
            <li>* Обработка персональных данных, где можно увидеть отправленные и полученные запросы на предоставление согласия по использованию персональных данных пользователя. Здесь отображается статус запроса, можно ознакомиться с текстом согласия.</li>
          </ul>
          <br/>
        </li>
        <li>Запрос согласия<br/>
            Перед созданием выбранного Договора необходимо получить согласие второй стороны на сбор и обработку персональных данных.
          <br/>Делается это следующим образом. Нажмите на кнопку «Создать контракт», выберете кейс, нажмите на кнопку «Запросить согласие», укажите ИИН второй Стороны, при необходимости можно указать комментарий. Нажмите кнопку «Отправить».
          <br/>Второй стороне придет запрос о необходимости предоставления согласия.
          <br/><br/>
        </li>
        <li>Заполнение данных.<br/>
        После того, как согласие будет предоставлено, можно перейти к созданию контракта и заполнению всех необходимых полей. Для этого необходимо нажать на стрелку справа от названия выбранного договора.
        <br/>После заполнения данных нажать на кнопку «Создать» контракт.
        <br/><br/>
        </li>
        <li>Подписание.<br/>
        После заполнения Договору будет присвоен статус "Черновик", вы сможете перепроверить введенные данные, после чего подписать Договор.
        <br/>После чего вторая сторона сможет увидеть Договор в разделе «Договоры с моим участием», ознакомиться с условиями и подписать Договор со своей стороны.
        </li>
        </ol>`,
      en: `
      <ol>
        <li>Authorization<br/>
          On the sc.egov.kz website, click the «Log in with eGov» button. Log in with your account on the e-government portal using a DS. Or log in to the «eGov mobile» application usingg a DS and select the "My Contracts" service.
          <br/>The service consists of 3 sections:
          <ul>
            <li>* My Contracts - a list of contracts created by the user</li>
            <li>* Joint Contracts - a list of contracts in which the user acts as the second party.</li>
            <li>* Personal data processing, where you can see the requests for consent to the use of the user's personal data that have been sent and received. Here you can have a look at the status of the request and the text of the consent.</li>
          </ul>
          <br/>
        </li>
        <li>Request for consent<br/>
            You should obtain the second party's consent to the collection and processing of personal data before entering into the selected contract.
          <br/>This is done as follows. Click «Create a contract» button, select a case, click «Request consent» button, enter the IIN of the second party, and if necessary write a comment. Click «Submit» button.
          <br/>The other party will be notified of the need to provide consent.
          <br/><br/>
        </li>
        <li>Filling in the data.<br/>
        Once consent has been given, you can proceed to the creation of a contract and the completion of all the necessary fields. To do this, click the arrow to the right of the selected contract name.
        <br/>After filling in the data, click «Create a contract» button.
        <br/><br/>
        </li>
        <li>Signing.<br/>
        Once completed, the contract will be set to "Draft" status and you will be able to double check the information you have entered before signing the contract.
        <br/>The other party will then be able to view the contract in the «Contracts with my participation» section, read the terms and conditions and sign the contract.
        </li>
        </ol>`
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Мобильді нұсқа бар ма?',
      ru: 'Есть ли мобильная версия?',
      en: 'Is there a mobile version available?'
    },
    answer: {
      kk: '«Smart Contract» сервисі sc.egov.kz сайтында және eGov mobile мобильді қосымшасында ұсынылған.',
      ru: 'Сервис «Smart Contract» представлен на сайте sc.egov.kz и в мобильном приложении eGov mobile.',
      en: 'The «Smart Contract» service is available on the sc.egov.kz website and in the «eGov mobile» application.'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Тіркеуден қалай өтуге болады?',
      ru: 'Как пройти регистрацию?',
      en: 'How do I register?'
    },
    answer: {
      kk: '«Smart Contract» сервисін пайдалану үшін сізге «Электрондық үкімет» порталында тіркелу және ҚР ҰКО-ның қолданыстағы ЭЦҚ-сы болуы жеткілікті.',
      ru: 'Для того чтобы воспользоваться сервисом «Smart Contract» вам достаточно иметь регистрацию на портале «электронного правительства» и действующую ЭЦП НУЦ РК.',
      en: 'To use the «Smart Contract» service, you only need to be registered on the e-Government portal and have a valid digital signature issued by the NCA RK.'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'ЭЦҚ деген не?',
      ru: 'Что такое ЭЦП? ',
      en: 'What is a DS?'
    },
    answer: {
      kk: `Электрондық цифрлық қолтаңба – электрондық цифрлық қолтаңба құралдарымен жасалған және электрондық құжаттың дұрыстығын, оның тиесілілігін және мазмұнының өзгермейтіндігін растайтын электрондық цифрлық таңбалар жиынтығы;
<br/>Электрондық цифрлық қолтаңба қол қоюшының өз қолымен қойған қолына тең және «Электрондық құжат және электрондық цифрлық қолтаңба туралы» Қазақстан Республикасының 2003 жылғы 7 қаңтардағы № 370-II Заңына сәйкес шарттарды орындау кезінде бірдей заңдық салдарға әкеп соғады.
<br/>Электрондық цифрлық қолтаңба (ЭЦҚ) үшін тіркеу куәлігін алғаннан кейін сіз оған тиісті жабық кілттің көмегімен электрондық құжаттарға қол қоя аласыз. Электрондық құжатта сіздің қолыңызды (ЭЦҚ) тексеруді оған қоса берілген тиісті тіркеу куәлігі бойынша жүзеге асыруға болады.
`,
      ru: `Электронная цифровая подпись - набор электронных цифровых символов, созданный средствами электронной цифровой подписи и подтверждающий достоверность электронного документа, его принадлежность и неизменность содержания;
<br/>Электронная цифровая подпись равнозначна собственноручной подписи подписывающего лица и влечет одинаковые юридические последствия при выполнении условий согласно Закона Республики Казахстан от 7 января 2003 года № 370-II «Об электронном документе и электронной цифровой подписи».
<br/>Получив регистрационное свидетельство для электронной цифровой подписи (ЭЦП) вы сможете при помощи соответствующего ему закрытого ключа, подписывать электронные документы. Проверку вашей подписи (ЭЦП) на электронном документе можно будет осуществить по приложенному к нему соответствующему регистрационному свидетельству.
`,
      en: `Digital signature - a set of electronic digital symbols created using digital signatures and used to confirm the authenticity of an electronic document, its ownership and the unchangeability of the content;
<br/>A digital signature is equivalent to the handwritten signature of the signatory and entails the same legal consequences when the conditions are met in accordance with the Law of the Republic of Kazakhstan as of January 7, 2003 No. 370-II «On Electronic Documents and Digital Signatures».
<br/>You can sign electronic documents using the corresponding private key once you have obtained a Digital Signature (DS) registration certificate. Verification of your signature (DS) on an electronic document can be carried out using the corresponding registration certificate attached thereto.
`
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'ҚР ҰКО дегеніміз не?',
      ru: 'Что такое НУЦ РК?',
      en: 'What is the NCA RK?'
    },
    answer: {
      kk: '<a href="https://pki.gov.kz/">Қазақстан Республикасының Ұлттық куәландырушы орталығы</a> мемлекеттік және мемлекеттік емес ақпараттық жүйелерде электрондық құжаттарды қалыптастыру үшін жеке немесе заңды тұлғаларға электрондық цифрлық қолтаңба құралдарын және тіркеу куәліктерін береді.',
      ru: '<a href="https://pki.gov.kz/">Национальный удостоверяющий центр Республики Казахстан</a> предоставляет средства электронной цифровой подписи и регистрационные свидетельства физическим или юридическим лицам для формирования электронных документов в государственных и негосударственных информационных системах.',
      en: 'The <a href="https://pki.gov.kz/">National Certification Authority of the Republic of Kazakhstan</a> is a certification authority that provides digital signature tools and registration certificates to individuals or legal entities for generating electronic documents in state and non-state information systems.'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'ЭЦҚ-ны қалай алуға болады?',
      ru: 'Как получить ЭЦП?',
      en: 'How to get a DS?'
    },
    answer: {
      kk: `ЭЦҚ алу туралы толығырақ мына сілтеме <a href="https://egov.kz/cms/ru/services/pass_onlineecp">https://egov.kz/cms/ru/services/pass_onlineecp</a> бойынша біле аласыз`,
      ru: 'Подробнее о получении ЭЦП можете узнать по ссылке <a href="https://egov.kz/cms/ru/services/pass_onlineecp">https://egov.kz/cms/ru/services/pass_onlineecp</a>',
      en: 'You can find out more about obtaining a DS by following the link:  <a href="https://egov.kz/cms/ru/services/pass_onlineecp">https://egov.kz/cms/ru/services/pass_onlineecp</a>',
    }
  },
  // {
  //   id: 1,
  //   show: false,
  //   question: {
  //     kk: 'Блокчейн дегеніміз не?',
  //     ru: 'Что такое Блокчейн?'
  //   },
  //   answer: {
  //     kk: 'Деректер бір-бірімен байланысты блоктарда сақталатын, осылайша деректердің өзгеріссіз сақталуын қамтамасыз ететін таратылған дерекқорлар мен жүйелерді іске асыру технологиясы. Өзгермейтіндіктің сенімділігі мен сақтаудың қол жетімділігіне бірнеше түйіндер мен осы түйіндердің блоктық жазбаларын тексеру процедуралары арасында тізілімнің (блокчейн тізбегінің) репликациясы арқылы қол жеткізіледі.',
  //     ru: 'Технология по реализации распределенных баз данных и систем, при которой данные хранятся в блоках, связанных друг с другом, тем самым обеспечивая неизменное хранение данных. Надежность неизменности и доступность хранения также достигается репликацией реестра (блокчейн-цепи) между несколькими узлами и процедурами верификации записи блоков данными узлами.\n'
  //   }
  // },
  {
    id: 1,
    show: false,
    question: {
      kk: 'Шарттардың заңды күші бар ма?',
      ru: 'Имеют ли договора юридическую силу?',
      en: 'Are contracts legally enforceable?',
    },
    answer: {
      kk: 'Иә, шарттар электрондық құжат түрінде жасалғандықтан, ақпарат электрондық-цифрлық нысанда ұсынылған және электрондық цифрлық қолтаңба арқылы куәландырылған құжат.',
      ru: 'Да, поскольку договора заключаются в виде электронного документа – документа, в котором информация представлена в электронно-цифровой форме и удостоверена посредством электронной цифровой подписи.\n',
      en: "Yes, since contracts are concluded in the form of an electronic document – a document in which information is presented in electronic form and certified by means of a digital signature."
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Электрондық шарттарды сақтау мерзімі?',
      ru: 'Срок хранения электронных договоров?',
      en: 'How long are electronic contracts stored?',
    },
    answer: {
      kk: '«Smart contract» арқылы жасалған құжаттар мерзімсіз сақталады.',
      ru: 'Документы, созданные посредством «Smart contract» хранятся бессрочно.',
      en: 'Documents created through the «Smart Contract» service are stored indefinitely.',
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Неліктен дербес деректерді жинауға және өңдеуге келісім сұрау керек?',
      ru: 'Зачем запрашивать согласие на сбор и обработку персональных данных?',
      en: 'Why consent to collecting and processing personal data is requested?'
    },
    answer: {
      kk: 'Қазақстан Республикасының Дербес деректер туралы заңнамасына сәйкес адамның және азаматтың құқықтары мен бостандықтарын қорғауды қамтамасыз ету мақсатында дербес деректерді жинау және өңдеу субъектінің немесе оның заңды өкілінің келісімімен жүзеге асырылады.',
      ru: 'В соответствии с законодательством Республики Казахстан о персональных данных в целях обеспечения защиты прав и свобод человека и гражданина сбор и обработка персональных данных осуществляются с согласия субъекта или его законного представителя.',
      en:'In accordance with the legislation of the Republic of Kazakhstan on personal data, in order to ensure the protection of the rights and freedoms of man and citizen, the collection and processing of personal data is carried out with the consent of the subject or his/her legal representative.'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Дербес деректерді жинауға және өңдеуге келісім бере алмауға бола ма?',
      ru: 'Могу ли я не давать согласие на сбор и обработку персональных данных?',
      en: 'Can I refuse to give my consent to the collection and processing of my personal data?'
    },
    answer: {
      kk: 'Егер Сізге дербес деректеріңізді алу туралы сұрау салу түскен болса, бірақ сіз өз келісіміңізді бергіңіз келмесе, онда «Дербес деректерді өңдеу» - «Алынған» бөлімінде сұрау салуды таңдап, «Қабылдамау» батырмасын басыңыз. Сұрау қабылданбайды және бастамашы сіздің деректеріңізді пайдаланып шарт жасай алмайды.',
      ru: 'Если к вам пришел запрос на получение ваших персональных данных, но вы не хотите давать свое согласие, то в разделе «Обработка персональных данных» - «Полученные» выберите запрос и нажмите на кнопку «Отклонить». Запрос будет отклонен и инициатор не сможет создать договор, используя ваши данные.',
      en: 'If you have received a request for your personal data but do not wish to give your consent, select the request in the «Personal Data Processing» - «Received» section and click «Reject» button. The request will be rejected and the initiator will not be able to create a contract using your data.'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Шартқа өзгерістерді қалай енгізуге болады?',
      ru: 'Как внести изменения в Договор?',
      en: 'How to make changes to the contract?'
    },
    answer: {
      kk: 'Егер шарт «Жоба» мәртебесінде болса, сіз «Деректерді өзгерту» батырмасын басып, қажетті өзгерістер енгізе аласыз, содан кейін енгізілген деректердің дұрыстығына тағы бір рет көз жеткізіп, Шартқа қол қоясыз. Қол қойылған Шартқа өзгерістер енгізілмейді.',
      ru: 'Если Договор находится в статусе «Черновик» вы можете нажать на кнопку «Изменить данные» и внести необходимые изменения, после чего еще раз убедившись в корректности внесённых данных подписать Договор. Подписанный Договор изменению не подлежит.',
      en: 'If the contract is in «Draft» status, you can click «Change data» button and make the necessary changes, and then, after double-checking that the data entered is correct, sign the contract. No changes can be made to the signed contract.'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Жасалған шарттарға қалай және қашан қол жеткізе аламын?',
      ru: 'Как и когда я могу получить доступ в заключенным договорам?',
      en: 'How and when can I access the contracts I have signed?'
    },
    answer: {
      kk: 'Сіз Тараптардың бірі болып табылатын шарттар сізге sc.egov.kz сайтындағы аккаунтыңызда және «eGov mobile» мобильді қосымшасында («Менің шарттарым» сервисі) кез келген уақытта қол жетімді.',
      ru: 'Договора, в которых вы являетесь одной из Сторон, доступны вам в любое время в вашем аккаунте на сайте sc.egov.kz и мобильном приложении eGov mobile (сервис Мои договора). ',
      en: 'Contracts to which you are a party can be viewed at any time in your account on the sc.egov.kz website and the «eGov mobile» application («My Contracts» service).'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Үшінші тұлғалар менің шарттарыма қол жеткізе ала ма?',
      ru: 'Могут ли третьи лица получить доступ к моим договорам?',
      en: 'Can third parties have access to my contracts?'
    },
    answer: {
      kk: 'Жоқ, шарттар тек шарт Тараптары үшін ғана қол жетімді',
      ru: 'Нет, договора доступны только для Сторон договора',
      en: 'No, the contracts are only available to the parties to the contract.'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Шарттарымды өзімде сақтауға бола ма?',
      ru: 'Могу ли я сохранить договора у себя?',
      en: 'Can I download the contracts?'
    },
    answer: {
      kk: 'Ия, сіз шарттарды .pdf форматта өзіңіздің құрылғыңызда сақтай аласыз.',
      ru: 'Да, вы можете сохранить договора в формате .pdf на вашем устройстве',
      en: 'Yes, you can download contracts in .pdf format to your device.'
    }
  },{
    id: 1,
    show: false,
    question: {
      kk: 'Мен немесе екінші Тарап шартты өшіріп тастай ала ма?',
      ru: 'Могу ли я или вторая Сторона удалить договор?',
      en: 'Is it possible for me or the other party to delete the contract?'
    },
    answer: {
      kk: 'Жоқ, қол қойылған шарттарды жою мүмкін емес',
      ru: 'Нет, подписанные договоры удалить невозможно',
      en: 'No, it is not possible to delete signed contracts'
    }
  },
];
