import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {defaultDateTimeFormat} from '../utils/constants';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-preview-item',
  templateUrl: './preview-item.component.html',
  styleUrls: ['./preview-item.component.css']
})
export class PreviewItemComponent implements OnInit, OnChanges {
  public dateTimeFormat = defaultDateTimeFormat;
  @Input() id;
  @Input() documentId;
  @Input() link;
  @Input() title;
  @Input() state;
  @Input() companyName;
  @Input() commentary;
  @Input() createdDate;
  @Input() participants = [];
  @Input() loadingAgreementLink: boolean;
  @Input() stateLoading = false;
  @Input() isRead: boolean;
  @Output() confirm = new EventEmitter();
  @Output() reject = new EventEmitter();
  @Output() download = new EventEmitter();
  public loading = false;

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
    }
  }
  navigateToLink(): void {
    this.router.navigate([this.link]);
  }
  onDownload(event: MouseEvent) {
    event.stopPropagation();
    this.download.emit(this.id);
  }
}
