import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {AuthService} from "../../_services/auth.service";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public dialogRef: DialogRef<ModalComponent>,
    @Inject(DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

  }

  closeDialog(): void {
    this.data.onClose();
    this.dialogRef.close();
  }

  rejectAgreement(){
    this.data.rejectRequest();
    this.closeDialog();
  }

}
