import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {IUser} from '../types/common.types';
import {Observable, of, Subject, throwError} from 'rxjs';

@Injectable()
export class AuthService {
  public redirectUrl = '/contracts';
  private apiUrl = environment.api_url;
  unreadCounter: Subject<boolean> = new Subject<boolean>();
  updateUnreadCountObserve = this.unreadCounter.asObservable();
  unreadAgreementCount: Subject<boolean> = new Subject<boolean>();
  updateUnreadAgreementCountObserve = this.unreadAgreementCount.asObservable();
  constructor(
    public router: Router,
    private httpClient: HttpClient
  ) {}

  get isLoggedIn() {
    return !!this.getCookie('SSO');
  }
  get token() {
    return this.getCookie('SSO');
  }
  get lang() {
    return this.getCookie('lang') || this.getCookie('egovLang') || this.getCookie('locale') || 'ru';
  }
  saveSSO(sso: string) {
    this.setCookie('SSO', sso, {'max-age': 3600});
    // window.localStorage.setItem('auth_token', sso);
  }
  get isDeviceMobile():boolean{
    return this.getCookie('X-Egov-Frame-Type') === 'MOBILE' || this.getCookie('egov-client-type') === 'MOBILE';
  }

  saveUser(user: any) {
    // if (user) {
    //   const parsedUser = this.parseRawDataToUser(user);
    //   localStorage.setItem('user', JSON.stringify(parsedUser));
    // }

    if (user) {
      const parsedUser = this.parseRawDataToUser(user);
      localStorage.setItem('user', JSON.stringify(parsedUser));
      // Set the cookie
      this.setCookie('user_data', JSON.stringify(parsedUser),{'max-age': 3600})
    }
  }
  get user(): IUser {
    // return (
    //   localStorage.getItem('user') &&
    //   JSON.parse(localStorage.getItem('user'))
    // );
    let getItemUser = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
    let getUserCookie = this.getCookie('user_data');
    let parsedUserCookie = getUserCookie ? JSON.parse(getUserCookie) : null;
    return parsedUserCookie || getItemUser;
  }

  get languageLocalStorage():any{
    return (
      localStorage.getItem('language') || this.getCookie('lang')
    );
  }
  get getUserInitial() {
    return this.user.first[0] + this.user.last[0];
  }
  get getUserName() {
    return (this.user) ? `${this.user.last} ${this.user.first && this.user.first[0]}.` : '';
  }
  updateUser(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  updateUnreadCount(updated) {
    this.unreadCounter.next(updated);
  }
  updateUnreadAgreementCount(updated) {
    this.unreadAgreementCount.next(updated);
  }
  /**
   *     Gets profile by sso, when user authorized from idp.egov.kz
   */
  getProfile(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}user_profile`, {withCredentials: true})
      .pipe(tap((res: any) => {
        if (res) {
          this.saveUser(res);
        } else {
          this.logout();
        }
      },
      catchError((err: any) => {
        this.logout();
        return throwError(err);
      })));
  }

  getCookie(name: string) {
    const matches = document.cookie.match(new RegExp(
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  setCookie(name, value, options: any = {}) {
    options = {
      path: '/',
      ...options
    };
    if (options.expires && options.expires.toUTCString) {
      options.expires = options.expires.toUTCString();
    }
    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }
    document.cookie = updatedCookie;
  }
  deleteCookie(name) {
    this.setCookie(name, '', {'max-age': -1});
  }

  parseRawDataToUser(rawUser: any): IUser {
    return {
      iin: rawUser.iin,
      last: rawUser.lastname,
      first: rawUser.firstname,
      middle: rawUser.middlename || '',
      org: {
        bin: rawUser.bin,
        ru: rawUser.orgNameRu,
        kk: rawUser.orgNameKk,
      },
      isLegal: !!rawUser.bin,
      fullName: `${rawUser.lastname} ${rawUser.firstname} ${rawUser.middlename || ''}`,
      // fullName: capitalizeFullName({last: rawUser.lastname, first: rawUser.firstname, middle: rawUser.middlename || ''}),
    };
  }

  sendCode(code: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}get-token`, { headers: {'Content-Type': 'application/json' }, params: {code}} );
  }

  login(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}user/login`, data, {headers: {'Content-Type': 'application/json'}})
      .pipe(
        tap(res => {
          if (res.message === 'SUCCESS') {
            this.saveUser(res.user);
          }
        })
      );
  }

  logout(redirect = true) {
    document.cookie = `SSO=; domain=${environment.domain}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    document.cookie = 'SSO=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
    document.cookie = 'user_data=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
    localStorage.removeItem('user');
    localStorage.removeItem('updatedContracts');
    if (redirect) {
      this.router.navigate(['landing']);
    }
  }
  getUnReadsCount(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}v1/contracts/read/count-unread/${this.user.iin}`, { headers: {'Content-Type': 'application/json' }} );
  }
  getAgreementUnReadsCount(): Observable<any> {
    const params = {
      acceptor: this.user.iin,
    };
    return this.httpClient.get(`${this.apiUrl}agreement/unread_count/`, {params});
  }
}
