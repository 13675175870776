<!--<button class="sidebar-list-item list-group-item d-flex align-items-start px-3 w-100 border-0 bg-transparent text-left" [class.contract-unread]="!isRead" (click)="navigateToLink()">-->
<!--  <div>-->
<!--    <img src="assets/icons/bi_file-text.svg" alt="file-icon" class="contract-svg"/>-->
<!--  </div>-->
<!--  <div class="w-100">-->
<!--    <small class="link black float-right mb-1">{{ createdDate | dateFormat: dateTimeFormat }}</small>-->
<!--    <h4 class="bodysub contract-title mb-2">{{ title }}</h4>-->
<!--    <p class="link black mb-2" [innerHtml]="participants"></p>-->
<!--    <div class="d-flex align-items-center justify-content-between mt-3 reverse-row-column g-1 card-action">-->
<!--      <div class="d-flex btn-my-primary">-->
<!--        <app-loader-->
<!--          *ngIf="loadingAgreementLink"-->
<!--          size="extraSmall"-->
<!--          [style.margin-top]="'5px'"-->
<!--        ></app-loader>-->
<!--        <span *ngIf="!loadingAgreementLink" class="download-svg mt-1"></span>-->
<!--        <button-->
<!--          class="btn btn-link d-inline-flex download"-->
<!--          (click)="onDownload($event)"-->
<!--        >-->
<!--          {{ 'Download the contract' | translate}}-->
<!--        </button>-->
<!--      </div>-->
<!--      <app-state [color]="state" [loading]="stateLoading">{{ 'Contract Statuses.' + state | translate }}</app-state>-->
<!--    </div>-->
<!--  </div>-->
<!--</button>-->
<button class="redesign-card w-100" [class.contract-unread]="!isRead" (click)="navigateToLink()">
  <div class="info-side">
    <div>
      <h3>{{ title }}</h3>
      <div>
        <p class="body2" *ngIf="documentId==='a1d13ed1-8e75-44f0-9e18-ee87971f5b92'">Совместно с ЭСФ</p>
      </div>
    </div>
    <div class="info-bottom">
      <app-state [color]="state" [loading]="stateLoading">{{ 'Contract Statuses.' + state | translate }}</app-state>
      <p class="body3 color-medium-gray participants" [innerHtml]="participants"></p>
      <p class="body3 color-medium-gray">{{ createdDate | dateFormat: dateTimeFormat }}</p>
      <a (click)="onDownload($event)" class="body3 mobile-view">
        {{ 'Download the contract' | translate}}
        <img src="assets/icons/icon-download.svg"/>
      </a>
    </div>
  </div>
  <a (click)="onDownload($event)" class="body3 desktop-view">
    {{ 'Download the contract' | translate}}
    <img src="assets/icons/icon-download.svg"/>
  </a>
</button>

