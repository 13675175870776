import {Component, OnDestroy, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../_services/auth.service';
import {ContractTypes} from '../../types/contract.types';
import {Subscription} from 'rxjs';
import {IDP_LINK} from '../../modules/share/utils/constants';
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public langDict = {
    kk: 'Kz',
    ru: 'Ru',
    en: 'En'
  };
  public langDictMobile = {
    kk: 'Kz',
    ru: 'Ru',
    en: 'En'
  };

  public timer;
  public minutes;
  public seconds;
  public currentLanguage = this.langDict[this.translate.currentLang];
  public currentLanguageMobile = this.langDictMobile[this.translate.currentLang];
  public languages = [
    { code: 'kk', name: 'Қазақша', title: 'Қазақша'},
    { code: 'ru', name: 'Русский', title: 'Русский'},
    { code: 'en', name: 'English', title: 'English'},
  ];
  public types = ContractTypes;
  public unreadCount = {
    party: 0,
    initiator: 0,
    personalData: 0,
  };
  interval: any;
  subscriber: Subscription;
  agreementSubscriber: Subscription;
  idpLink = IDP_LINK;
  isNavbarOpen = false;


  constructor(
    private router: Router,
    public authService: AuthService,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    if (this.authService.user) {
      this.getUpdatesCount();
      this.getAgreementUnReadsCount();
    } else {
      this.authService.getProfile();
    }
    // updates count every 5 minutes
    this.interval = setInterval(() => {
      this.getUpdatesCount();
      this.getAgreementUnReadsCount();
    }, 1000 * 60 * 5);

    this.subscriber = this.authService.updateUnreadCountObserve.subscribe(res => {
      if(res) {
        this.getUpdatesCount();
      }
    });
    this.agreementSubscriber = this.authService.updateUnreadAgreementCountObserve.subscribe(res => {
      if (res) {
        this.getAgreementUnReadsCount();
      }
    });
  }
  ngOnDestroy() {
    clearInterval(this.interval);
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
  redirectToIdp() {
    this.authService.logout(false);
    window.location.href = IDP_LINK;
  }
  setLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.authService.setCookie('lang', lang);
    this.currentLanguage = this.langDict[lang];
    this.currentLanguageMobile = this.langDictMobile[lang];
  }
  navigateToIdpLink(){
    window.location.href = this.idpLink;
  }
  toggleNavbar() {
    this.isNavbarOpen = !this.isNavbarOpen;
    const navbarCollapse = document.getElementById('navbarCollapse');
    const hamburger = document.getElementById('hamburger');
    if (navbarCollapse) {
      if (this.isNavbarOpen) {
        navbarCollapse.classList.add('open');
        hamburger.classList.add('active'); // Add active class
      } else {
        navbarCollapse.classList.remove('open');
        hamburger.classList.remove('active'); // Remove active class
      }
    }
  }
  closeNavbar(){
    this.isNavbarOpen = false;
    const navbarCollapse = document.getElementById('navbarCollapse');
    const hamburger = document.getElementById('hamburger');
    if (navbarCollapse) {
      if (this.isNavbarOpen) {
        navbarCollapse.classList.add('open');
        hamburger.classList.add('active'); // Add active class
      } else {
        navbarCollapse.classList.remove('open');
        hamburger.classList.remove('active'); // Remove active class
      }
    }
  }

  logout() {
    this.authService.logout(true);
  }

  private getUpdatesCount() {
    if (this.authService.user) {
      this.authService.getUnReadsCount().subscribe(res => {
        [this.unreadCount] = res;
      });
    }
  }
  private getAgreementUnReadsCount() {
    if (this.authService.user) {
      this.authService.getAgreementUnReadsCount().subscribe(res => {
        this.unreadCount.personalData = res.result.unread_acceptor_count;
      });
    }
  }

  isLanding() {
    return this.router.url.includes('landing');
  }
}
