<nav
        class="sidebar navbar navbar-expand-lg"
        [ngClass]="isNavbar ? 'navbar-styles' : ''"
>
<!--  <button class="navbar-toggler float-right ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"-->
<!--          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">-->
<!--    <span class="navbar-toggler-icon"></span>-->
<!--  </button>-->
  <div class="w-100">
    <button routerLink="contracts/create" (click)="close()"
            class="btn btn-primary w-100">{{'Create contract' | translate}}</button>
    <div class="divider w-100"></div>
    <ul>
      <li class="nav-item">
        <a
          routerLink="contracts"
          [queryParams]="{party: false}"
          routerLinkActive="active"
          [class.active]="active===1"
          (click)="isNewRoute(false)"
          class="nav-link header-menu__link"
        >
          {{'Outbox' | translate}}
          <span class="count" *ngIf="unreadCount.initiator > 0">{{unreadCount.initiator}}</span>
          <img
            *ngIf="active!==1"
            [src]="'assets/icons/icon-arrow.svg'" alt="arrow icon"/>
          <img
            *ngIf="active===1"
            [src]="'assets/icons/icon-arrow-active.svg'" alt="arrow icon"/>
        </a>
      </li>
      <li class="nav-item">
        <a
          routerLink="contracts"
          [queryParams]="{party: true}"
          routerLinkActive="active"
          class="nav-link header-menu__link"
          (click)="isNewRoute(true)"
        >
          {{'Inbox' | translate}}
          <span class="count" *ngIf="unreadCount.party > 0">{{unreadCount.party}}</span>
          <img
            *ngIf="active!==2"
            [src]="'assets/icons/icon-arrow.svg'"
            alt="arrow icon"
          /><img
            *ngIf="active===2"
            [src]="'assets/icons/icon-arrow-active.svg'"
            alt="arrow icon"
          />
        </a>
      </li>
      <li class="nav-item">
        <a
          routerLink="request"
          routerLinkActive="active"
          class="nav-link header-menu__link"
          (click)="isNewRoute(false,true)"
        >
          {{'Personal data processing' | translate}}
          <span class="count" *ngIf="unreadCount.personalData > 0">{{unreadCount.personalData}}</span>
          <img
            *ngIf="active!==3"
            [src]="'assets/icons/icon-arrow.svg'"
            alt="arrow icon"
          />
          <img
            *ngIf="active===3"
            [src]="'assets/icons/icon-arrow-active.svg'"
            alt="arrow icon"
          />
        </a>
      </li>
<!--      <li class="nav-item">-->
<!--        <a      (click)="logout()"-->
<!--                class="nav-link header-menu__link color-red"-->
<!--        >-->
<!--          {{'Logout' | translate}}-->
<!--          <span class="count" *ngIf="unreadCount.personalData > 0">{{unreadCount.personalData}}</span>-->
<!--          <img-->
<!--                  [src]="'assets/icons/icon-arrow-red.svg'"-->
<!--                  alt="arrow icon"-->
<!--          />-->
<!--        </a>-->
<!--      </li>-->
    </ul>
  </div>

</nav>
